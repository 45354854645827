
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";


// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


@import "common";


// Colors
$color-1: #2ecc71;
$color-4: #e74c3c;

.custom-radios {
  
    div {
      display: inline-block;
    }
  
    input[type="radio"] {
      display: none;
  
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
  
        span {
          display: inline-block;
          width:20px;
          height: 20px;
          margin: -1px 4px 0 0;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 50%;
          border: 2px solid #FFFFFF;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.33);
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
          line-height: 20px;
          background-color: #ccc;
      
          img {
            opacity: 0;
            transition: all .1s ease;
          }
        }
      }
  
      &.color-1 {
        background-color: $color-1;
      }
  
      &.color-4{
        background-color: $color-4;
      }
  
      &:checked + label span img {
        opacity: 1;
      }

      &:checked + label span.color1  {
         background-color: $color-1;
      }
      &:checked + label span.color4  {
        background-color: $color-4;
     }
    }
  }