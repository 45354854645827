

/**
override input field background for datepicker component
 */

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
  background-color: white !important;
}

.btn-md {
  padding: 10px 50px !important;
}
.btn-xs {
  padding: 5px 10px !important;
}

.back-top {
  padding: 7px 9px !important;

}

.required {
  color: $red;
}

.form-group input + span ,  .form-group select + span , .is-danger {
  color: $red;
}

.radio-btn{
  width: 25px !important;
}



.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #dddddd !important;
}

.preloader {
  background-color: #fff;
  height: 100%;
  position: absolute;
  z-index: 9999;
  width: 98%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.preloader-item {
  background-color: #fff;
  height: 100%;
  position: absolute;
  z-index: 9998;
  width: 98%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.loading1 {
  width: 300px;
  height: 100px;
  margin: auto;
  // background: url('../images/loading_bar.gif') center no-repeat;
}

.loading2 {
  width: 300px;
  height: 300px;
  margin: auto;
  //background: url('../images/loading.gif') center no-repeat;
}
.actions .btn{
  padding:5px 10px !important;
  margin: 2px;
}

.actions {
  width : 150px;
  text-align: right;
}

.dash-link{
  color: #87817a !important;
  :hover{
    color: #33322e;

  }
}

.white-txt{
  color: #FFFFFF;
}

.cardbox{
  color:#ffffff !important;
}

#cardbox1{
  background-color:#73648a !important;

}

#cardbox2{
  background-color:#c988a2 !important;
}

#cardbox3{
  background-color:#418e9a !important;
}

#cardbox4{
  background-color:#537851 !important;
}

.home__widget{
  max-height: 250px !important;
  min-height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  border-radius: 0 !important;
  vertical-align: middle;
  min-height: 70px;
}

.panel {
  border: 1px solid #EEEEEE !important;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #aaaaaa;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 1px solid #aaaaaa;
}
//.sidebar-menu .level3 .treeview-menu  {
//  padding: 5px 0 0px;
//  padding-left: 0px;
//}
//.sidebar-menu .level3 .treeview-menu > li::before {
//  border :none;
//}
//.sidebar-menu .level3 .level2 a {
//    font-size: 16px;
//    background: #d0d0d0;
//
//}
//
//.sidebar-menu .level3 .level2 .treeview-menu a {
//  font-size: 13px;
//  background: #E5E5E5;
//}
//
//.sidebar-menu .level3  .treeview-menu > li.active > a {
//  color: #e4b75a;
//}
//
//.sidebar-menu .level3  .treeview-menu > li > a {
//  color: #696969;
//}

.toolbar ul .icon_box {
  min-height: auto;
  collapse: #596989;

}
.toolbar ul > li.active > a, .toolbar > li.active > a:focus, .toolbar > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd !important;
}

.panel-heading.toolbar {
  border-bottom-color: #EEEEEE !important;
}

.vis-item {
  border-color: #c0c0c0 !important;
  background-color: #EEEEEE !important;
}

.btn-back {
  color: #758A9F !important;
}



.vis-timeline {
  border: none !important;
}

.leave-types table {
  font-size: 10px;
  width : 100%
}

.disableField {
  border: 1px solid #c0c0c0;
  padding: 5px;
  background: #EEEEEE;
}

.hvr-icon-float .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.nav-tabs li .label-success {
  background-color: $gray;
  border: 2px solid $gray;
}

.nav-tabs li.active .label-success {
  background-color: $green;
  border: 2px solid $green;
}



body{

  font-family: 'Montserrat', sans-serif !important;
}
//Leave Types Color Codes
.color_an { color : #53cde2; }
.color_ca { color : #d7b72e; }
.color_sl { color : #af0037; }
//ff70d1 08415c

.nav-tabs {
  border-bottom: none !important;

}

.icon_box {
  text-transform: uppercase;
}

#tab1lh .btn , #tab2lh .btn {
  border-radius: 0px !important;

}

.gray-mediem-bg-input{
  border: 1px solid #f8f8f8 !important;
  background: #f4f4f4 !important;
}



.tabs-btn > li.active > a, .tabs-btn > li.active > a:hover, .tabs-btn > li.active > a:focus {
  border-bottom-color: #e98d09;
  border-right-color: #e98d09;
  background-color: #e98d09;
  color: #fff;
}

.message_ticker {
  width: 800px;
  height:30px;
  overflow:hidden;
  float:left;
  border-right:1px solid #333;
  -webkit-transition: 0.5s ease-in;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;

}

.message_ticker:hover {
  -webkit-transition: 4s ease-in;
  -moz-transition: 4s ease-in;
  -o-transition: 4s ease-in;
  transition: 4s ease-in;
  text-indent: -200px;
}


.cal-event {
  li { cursor: pointer; }
  .leave_type {
    &_an {
        color: #0d6aad;
        position: absolute;
        left:10%;
        bottom:20%;

    }
    &_ca {
        color: #3c763d;
        position: absolute;
        left:10%;
       
    }
  }
}


.hrms{
  &__form-group{
    margin-bottom: 8px;
    .control-label{
      font-size: 12px;
    }
    .input-group {
      width: 100% !important;
    }
    .input-group-addon{
      font-size: 12px;
      border-color: $border-color-default;
      border-right: 0px solid $border-color-default;
      border-radius: 1px;
      height: 34px;
      background-color: transparent;
      color: $text-color;
      span{
        &:nth-child(1){
          margin-right: 10px;
        }
      }
      &.active{
        background-color: $gray-1;
        border-color: $orange-medium;
        cursor: pointer;
        color: $orange-medium;
      }
    }
    input[type=text],input[type=email],input[type=password],select{
      &.form-control{
        border-radius: 1px;
        border-color: $border-color-default;
        height: 34px;
        padding: 6px 12px;
        box-shadow: none;
        font-size: 12px;
        border-left: 0px solid $border-color-default;
      }
    }
    .help{
      font-size: 11px;
      color: $red;
    }
    &--has-icon{
      input[type=text],input[type=email],input[type=password]{
        border-left: 0px;
      }
    }
    &--has-action{
      .input-group-addon{
        &:hover{
          background-color: $gray-1;
          border-color: $orange-medium;
          cursor: pointer;
          color: $orange-medium;
        }
      }
    }
  }
  &__btn{
    border-radius: 1px;
    padding: 6px 20px;
    outline: none;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    &:focus{
      outline: none;
    }
    &:hover{
      border-color: $orange-medium;
      color: $orange-medium;
      background-color: #fff;
    }
    &--blue{
      background-color: $blue-medium;
      color: #fff;
      border-color: $blue-medium;
    }
    &--default {
      background-color: $gray-blueish;
      color: #000;
      border-color:  $gray-blueish;

    }
    i{
      margin-right: 10px;
    }
  }
}

.timeline .panel-body {

  background-color: #47444e;

  .vis-time-axis .vis-text {
    color: #FFFFFF;
  }
}

.vdp-datepicker{
  //@media (min-width: 1024px){
  //  position: static !important;
  //}
}

.vdp-datepicker__calendar {
  top:36px !important;
  //width:100% !important;
  border-radius:3px ;
  -webkit-border-radius:3px ;
  -moz-border-radius: 3px;
  width: 285px !important;
}

.vdp-datepicker__calendar .cell{
  height: 32px !important;
  line-height: 32px !important;
}

.vdp-datepicker__clear-button {
  position: absolute !important;


}

@media only screen and (max-width: 1400px) {
  .accordian-table{
    max-width: 1000px;
  }
}


//.table-fixed{
//  width: 100%;
//  background-color: #f3f3f3;
//  tbody{
//    height:200px;
//    overflow-y:auto;
//    width: 100%;
//  }
//  thead,tbody,tr,td,th{
//    display:block;
//  }
//  tbody{
//    td{
//      float:left;
//    }
//  }
//  thead {
//    tr{
//      th{
//        float:left;
//        background-color: #f39c12;
//        border-color:#e67e22;
//      }
//    }
//  }
//}


//ESS FORM



