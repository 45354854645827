
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;


// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 12px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


// Common
$red: #ff3860;
$gray: #424574;
$green: #50ab20;
$orange: #ff7b3a;

//ESS


$gray-1: #fcfcfc;
$gray-2: #ececec;
$gray-3: #dddddd;
$gray-4: #cccccc;
$gray-5: #aaaaaa;
$gray-6: #999999;
$gray-7: #777777;
$gray-8: #555555;

$gray-light: #cccccc;
$gray-normal: #999999;
$gray-medium: #666666;
$gray-dark: #333333;
$gray-blueish : #e7e7e7;

$orange-light: #ffc059;
$orange-normal: #ffb43d;
$orange-medium: #f39f1b;
$orange-dark: #e88011;

$red: #ee001c;

$blue-medium : #367fa9;


$border-color-default: $gray-light;
$border-color-hover: $gray-medium;
